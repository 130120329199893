import React from "react";
import classNames from "classnames";
// import Image from "next/image";

export interface IProps {
   lazy?: boolean;
   direction?: "vertical" | "horizontal";
   onLoadComplete?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
   onClick?: () => void;
   sizes?: string;
   resolution?: {
      xs?: string;
      sm?: string; // common
      md?: string; // common
      lg?: string;
   };
   src?: string;
   alt?: string;
   className?: string;
   classNameContainer?: string;
   objectFit?: "contain" | "cover" | "fill" | "scale-down" | "inherit";
   [key: string]: any;
}

export const ImageView = (props: IProps) => {
   const {
      lazy,
      onClick,
      onLoadComplete,
      direction = "horizontal",
      src,
      sizes,
      resolution,
      alt,
      objectFit,
      classNameContainer,
      className = "",
      ...otherProps
   } = props;
   const ref = React.useRef<HTMLImageElement | null>(null);
   function onLoaded(e: React.SyntheticEvent<HTMLImageElement, Event>) {
      if (e?.currentTarget.complete) {
         e.currentTarget?.classList.remove("img-loading");
         onLoadComplete && onLoadComplete(e);
      }
   }

   function onError(e: React.SyntheticEvent<HTMLImageElement>) {
      if (e.currentTarget) {
         e.currentTarget.removeAttribute("srcSet");
         e.currentTarget.onerror = () => {
            e.currentTarget?.classList.remove("img-loading");
            e.currentTarget?.classList.add("img-error");
         };
      }
   }

   const defaultImageUrl = direction === "vertical" ? "/assets/img/icon/no-data.png" : "/assets/img/icon/no-image-horizontal.png";
   return (
      <div className={classNames("image-wrapper", classNameContainer, src ? "" : "img-empty")} onClick={onClick}>
         <picture>
            {resolution?.xs && <source media="(max-width:374px)" srcSet={resolution?.xs} />}
            {resolution?.sm && <source media="(max-width:767px)" srcSet={resolution?.sm} />}
            {resolution?.md && <source media="(max-width:1023px)" srcSet={resolution?.md} />}
            {resolution?.lg && <source media="(min-width:1024px)" srcSet={resolution?.lg} />}
            <img
               {...otherProps}
               // ref={ref}
               // width={100}
               // height={100}
               src={src || defaultImageUrl}
               alt={alt || "Space T"}
               style={{
                  objectFit,
                  // ...(!src ? { maxWidth: "300px" } : undefined)
               }}
               className={`${className} img-loading ${direction}`}
               onLoad={(e: React.SyntheticEvent<HTMLImageElement, Event>) => onLoaded(e)}
               onError={(e) => onError(e)}
            />
         </picture>
      </div>
   );
};
